import { observer } from "mobx-react-lite";
import React from "react";
import Router from "./router/router";
import { AuthProvider } from "react-auth-kit";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";

const App = observer(() => {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </AuthProvider>
  );
});

export default App;
