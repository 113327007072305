import { RequireAuth, useAuthUser } from "react-auth-kit";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ListGenerator from "../components/listGenerator";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Profile from "../pages/profile/Profile";

export default function Router() {
  const auth = useAuthUser();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth loginPath={"/"}>
              {auth()?.role === "superadmin" || auth()?.role === "admin" ? (
                <Dashboard />
              ) : (
                <Navigate to={"/profile"} />
              )}
            </RequireAuth>
          }
        />
        <Route
          path="/gerador-de-listas"
          element={
            <RequireAuth loginPath={"/"}>
              {auth()?.role === "superadmin" ? (
                <ListGenerator />
              ) : (
                <Navigate to={"/profile"} />
              )}
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth loginPath={"/"}>
              <Profile />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to={"/profile"} replace />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
