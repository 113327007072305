import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { api } from "../../services/api";
import { useAuthUser } from "react-auth-kit";
import userTestsListStore from "../../mobx/userTestsList.store";
import LoadingButton from "@mui/lab/LoadingButton";

export function ScriptBox() {
  const auth = useAuthUser();
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setHelperText(" ");
    setError(false);
  };

  const createNewAccount = (endpoint: string) => {
    setLoading(true);
    api
      .get(`${endpoint}`, { headers: { Authorization: auth()?.token } })
      .then((res) => {
        setHelperText("Gerado com sucesso.");
        api
          .get("/profile", {
            headers: { Authorization: auth()?.token },
          })
          .then((res) => {
            userTestsListStore.setTestList(res.data.accounts);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err.response.data);
        setHelperText("Erro ao gerar plano.");
        setLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    switch (value) {
      case "elite":
        createNewAccount("/plans/elite");
        break;
      case "win":
        createNewAccount("/plans/win");
        break;
      case "pro":
        createNewAccount("/plans/pro");
        break;
      case "vpn":
        createNewAccount("/plans/vpn");
        break;
      default:
        setError(true);
        setHelperText("Por favor selecione uma opção.");
        break;
    }
  };

  const options = [
    { value: "elite", label: "Box", disabled: false },
    { value: "win", label: "Win", disabled: false },
    { value: "pro", label: "Pro" },
    { value: "vpn", label: "Connect" },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        style={{ backgroundColor: "#FFF" }}
        sx={{ m: 3, p: 3, borderRadius: "5px" }}
        error={error}
        variant="standard"
      >
        <FormLabel id="demo-error-radios">
          Selecione o plano desejado:
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-error-radios"
          name="gerador de planos"
          value={value}
          onChange={handleRadioChange}
        >
          {options.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio />}
              label={item.label}
              disabled={item.disabled}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <LoadingButton
          loading={loading}
          sx={{ mt: 1, mr: 1 }}
          type="submit"
          variant="outlined"
        >
          Gerar teste
        </LoadingButton>
      </FormControl>
    </form>
  );
}
