import { Chip } from "@mui/material";

export default function TableUserStatus({ status }: any) {
  return (
    <>
      {status === "active" ? (
        <Chip color={"success"} label={status} />
      ) : (
        <Chip color={"error"} label={status} />
      )}
    </>
  );
}
