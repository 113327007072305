import { makeObservable, observable, action } from "mobx";

export interface IUser {
  _id: number;
  name: string;
  email: string;
  accounts: Array<object>;
  createdAt: Date;
  updatedAt: Date;
  role: string;
  status: string;
  expiresIn: Date;
}

class UserListStore {
  users: any = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      setUsers: action,
    });
  }

  setUsers(users: Array<IUser>) {
    this.users = [];
    users.forEach((user, index) => {
      this.users.push({
        id: index + 1,
        _id: user._id,
        email: user.email,
        name: user.name,
        accounts_count: user.accounts.length,
        createdAt: new Date(user.createdAt).toLocaleString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
        updatedAt: new Date(user.updatedAt).toLocaleString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
        role: user.role,
        status: user.status,
        expiresIn: user.expiresIn
          ? new Date(user.expiresIn).toLocaleString("pt-BR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })
          : "nao definido",
      });
    });
  }
}

export default new UserListStore();
