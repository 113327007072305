import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Store from "../../mobx/global.store";
import { observer } from "mobx-react-lite";
import { FormHelperText, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./modal_password.css";
import { api } from "../../services/api";
import { useAuthUser } from "react-auth-kit";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default observer(function ChangePasswordModal() {
  const auth = useAuthUser();
  const handleClose = () => Store.setModal(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (passwords.oldPassword.length < 6) {
      setHelperText("A nova senha deve ter no mínimo 6 caracteres");
      setLoading(false);
      return;
    }

    const data = {
      oldPassword: passwords.oldPassword,
      newPassword: passwords.newPassword,
    };

    api
      .post(`/change_password`, data, {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setHelperText("Senha alterada com sucesso.");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setHelperText("Erro ao alterar senha.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Store.ChangePasswordModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Store.ChangePasswordModal}>
          <Box className="modal-custom" sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Alterar senha
            </Typography>
            <form
              onSubmit={handleSubmit}
              style={{
                padding: 30,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Grid
                container
                spacing={3}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Senha atual"
                    type="text"
                    placeholder="Digite sua senha atual..."
                    value={passwords.oldPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPasswords({
                        ...passwords,
                        oldPassword: e.target.value,
                      })
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nova senha"
                    type="text"
                    placeholder="Digite sua nova senha..."
                    value={passwords.newPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPasswords({
                        ...passwords,
                        newPassword: e.target.value,
                      })
                    }
                  ></TextField>
                </Grid>

                <Grid item xs={12}>
                  <FormHelperText>{helperText}</FormHelperText>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    style={{ marginTop: 1, marginRight: 1 }}
                    variant="outlined"
                  >
                    Alterar
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});
