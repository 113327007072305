import React, { useState } from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { Navigate, useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { Grid, TextField, Button } from "@material-ui/core";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./login.css";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<any>({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    await api
      .post(`/login`, payload)
      .then((res) => {
        // console.log(res.data);
        if (
          signIn({
            token: res.data.token,
            expiresIn: res.data.expiresIn,
            tokenType: "Bearer",
            authState: {
              email: res.data.email,
              name: res.data.name,
              role: res.data.role,
              token: res.data.token,
            },
          })
        ) {
          setLoading(false);
          localStorage.setItem("auth-token", res.data.token);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
        if (err.response.data.message === "Invalid password") {
          window.alert("Email ou senha inválidos!");
        }

        if (err.response.data.message === "User not authorized") {
          window.alert("Usuário não encontrado!");
        }

        if (
          err.response.data.message === "User not active. Please contact admin"
        ) {
          window.alert("Usuário desativado!");
        }
      });
  }

  if (isAuthenticated()) {
    return <Navigate to={"/dashboard"} replace />;
  } else {
    return (
      <>
        <Box className="bg-image-custom">
          <form
            style={{
              padding: 30,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={3}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className="size-custom"
              style={{
                backgroundColor: "#FFF",
                borderRadius: "10px",
                width: "50%",
              }}
            >
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="text"
                  placeholder="Digite seu email..."
                  value={payload.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPayload({ ...payload, email: e.target.value })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Senha"
                  type="password"
                  placeholder="Digite sua senha..."
                  value={payload.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                ></TextField>
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  style={{ marginTop: 1, marginRight: 1 }}
                  variant="outlined"
                >
                  Fazer login
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    );
  }
}
