import { Button } from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import userListStore from "../../mobx/userList.store";
import { api } from "../../services/api";

export default function ActionButton({ id, status, email }: any) {
  const auth = useAuthUser();

  function handleDisableUser() {
    api
      .put(
        "/delete-user",
        {
          userId: id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth()?.token,
          },
        }
      )
      .then((res) => {
        api
          .get("/list-users", {
            headers: { Authorization: auth()?.token },
          })
          .then((a) => {
            userListStore.setUsers(a.data.users);
          });
      })
      .catch((err) => {
        console.log(err.response.data);
        window.alert("Você não pode desativar você mesmo!");
      });
  }

  function handleEnableUser() {
    api
      .put(
        "/enable-user",
        { userId: id },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth()?.token,
          },
        }
      )
      .then((res) => {
        api
          .get("/list-users", {
            headers: { Authorization: auth()?.token },
          })
          .then((a) => {
            userListStore.setUsers(a.data.users);
          });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  return (
    <>
      <Button
        style={{ marginTop: 1, marginRight: 1, fontSize: "0.7rem" }}
        variant="contained"
        onClick={() => {
          if (status === "active") {
            if (
              window.confirm(
                `Tem certeza que deseja desativar o usuário ${email}?`
              )
            ) {
              handleDisableUser();
            }
          } else {
            if (
              window.confirm(
                `Tem certeza que deseja ativar o usuário ${email}?`
              )
            ) {
              handleEnableUser();
            }
          }
        }}
      >
        {status === "active" ? "Desativar" : "Ativar"}
      </Button>
    </>
  );
}
