import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CustomHeader } from "../../components/header/Header";
import { ScriptBox } from "../../components/generateBox/GenerateBox";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { api } from "../../services/api";
import { toJS } from "mobx";
import UserProfileStore from "../../mobx/userTestsList.store";
import { observer } from "mobx-react-lite";
import { Button, IconButton } from "@mui/material";
import "./profile.css";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default observer(function Profile() {
  const auth = useAuthUser();

  const donwloadFile = (path: string) => {
    window.open(`${api.defaults.baseURL}/download/${path}`, "_blank");
  };

  return (
    <>
      <CustomHeader />

      <Box className="bg-image-custom" style={{ height: "100%" }}>
        <Grid container justifyContent="center">
          <ScriptBox />
        </Grid>

        {auth()?.role === "user" && (
          <Grid container justifyContent="center">
            <Box
              style={{
                backgroundColor: "#FFF",
                padding: 5,
                borderRadius: 5,
                marginBottom: 20,
              }}
            >
              Sua conta vence em:{" "}
              {new Date(UserProfileStore.profileData.expiresIn).toLocaleString(
                "pt-BR",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }
              )}
            </Box>
          </Grid>
        )}

        <Grid container justifyContent="center">
          <TestAccountsList />
        </Grid>
        <Box sx={{ padding: 5 }}>
          <Grid container gap={3} justifyContent="center">
            <Grid item justifyContent={"flex-start"}>
              <Box style={{ width: 310 }}>
                <strong>Plano PRO:</strong> <br />
                DNS:51.222.156.94 ou 66.70.144.120 <br />
                DNS:177.67.82.167 ou 135.148.32.77 <br />
                URL E SMARTERS: http://zeusplay.life:80
              </Box>
            </Grid>
            <Grid item justifyContent={"flex-start"}></Grid>
            <Grid item justifyContent={"flex-start"}>
              <Box style={{ width: 310 }}>
                Download app plano <strong>WIN</strong>
                <Button onClick={() => donwloadFile("win")}>aqui</Button> <br />
                Download app <strong>CONNECT CLUB</strong>
                <Button onClick={() => donwloadFile("connect")}>aqui</Button>
                <br />
                Para Gerar lista m3u clique
                <Button
                  rel="noopener noreferrer"
                  href="https://wishbox-geradordelink.netlify.app/"
                  target="_blank"
                >
                  aqui
                </Button>
                <br />
                Para abrir o web player clique{" "}
                <Button
                  rel="noopener noreferrer"
                  href="http://webwishbox.com/"
                  target="_blank"
                >
                  aqui
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
});

const TestAccountsList = observer(() => {
  const [loading, setLoading] = useState(true);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "accessCode",
      headerName: "Código de Acesso",
      width: 150,
    },
    {
      field: "password",
      headerName: "Senha",
      width: 150,
    },
    {
      field: "plan",
      headerName: "Plano",
      type: "date",
      width: 100,
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 100,
    },
    {
      field: "expires",
      headerName: "Expira em",
      width: 180,
    },
    {
      field: "share",
      headerName: "Compartilhar",
      width: 100,
      renderCell: (rowData) => {
        return (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <WhatsappShareButton
                title={"Wishboxcash Gerador de contas"}
                separator={` -> \n`}
                url={`Seu acesso é:\n Codigo de acesso: *${
                  rowData.row.accessCode
                }* \n Senha: *${
                  rowData.row.password === ""
                    ? "P2P não utiliza senha."
                    : rowData.row.password
                }* \n Plano: *${rowData.row.plan}*\n Tipo: *${
                  rowData.row.type
                }*\n Expira em: *${rowData.row.expires}*`}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>

              <IconButton
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `Seu acesso é:\n Codigo de acesso: *${
                      rowData.row.accessCode
                    }* \n Senha: *${
                      rowData.row.password === ""
                        ? "P2P não utiliza senha."
                        : rowData.row.password
                    }* \n Plano: *${rowData.row.plan}*\n Tipo: *${
                      rowData.row.type
                    }*\n Expira em: *${rowData.row.expires}*`
                  );
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
          </>
        );
      },
    },
  ];

  const auth = useAuthUser();

  useEffect(() => {
    api
      .get("/profile", {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        // console.log(res.data);
        UserProfileStore.setProfileData(res.data);
        UserProfileStore.setTestList(res.data.accounts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  }, []);

  return !loading ? (
    <Box sx={{ height: 600, width: "80%" }}>
      <DataGrid
        style={{ backgroundColor: "#FFF" }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        rows={toJS(UserProfileStore.testList)}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
      />
    </Box>
  ) : (
    <div>Sem registro até o momento.</div>
  );
});
