import { makeObservable, observable, action } from "mobx";
import { ReactNode } from "react";

interface IUserProfile {
  id: number;
  accessCode: string;
  password: string;
  plan: string;
  type: string;
  expires: string;
}

class UserProfileStore {
  profileData: any = [];
  testList: any = [];
  constructor() {
    makeObservable(this, {
      profileData: observable,
      setProfileData: action,
      testList: observable,
      setTestList: action,
    });
  }

  setProfileData(profileData: any) {
    this.profileData = [];
    this.profileData = profileData;
  }

  setTestList(testList: Array<IUserProfile>) {
    this.testList = [];
    console.log(this.testList);
    testList.forEach((test, index) => {
      this.testList.push({
        id: index + 1,
        accessCode: test.accessCode,
        password: test.password,
        plan: test.plan,
        type: test.type,
        expires: new Date(test.expires).toLocaleString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
      });
    });
  }
}

export default new UserProfileStore();
