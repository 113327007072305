import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import { observer } from "mobx-react-lite";
import userListStore, { IUser } from "../../mobx/userList.store";
import { toJS } from "mobx";
import ActionButton from "../actionbutton/ActionButton";
import TableUserStatus from "../tableUserStatus/TableUserStatus";
import { api } from "../../services/api";

export default observer(function UserList() {
  const [loading, setLoading] = useState(true);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
    },
    {
      field: "accounts_count",
      headerName: "Contas Criadas",
      width: 150,
    },
    {
      field: "role",
      headerName: "Cargo",
      width: 120,
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Criado em",
      type: "date",
      width: 160,
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em",
      type: "date",
      width: 160,
    },
    {
      field: "expiresIn",
      headerName: "Expira em",
      type: "date",
      width: 160,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      width: 150,
      renderCell: ({ row }: { row: IUser }) => {
        return <TableUserStatus status={row.status} />;
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      align: "center",
      width: 100,
      renderCell: ({ row }: { row: IUser }) => {
        return (
          <ActionButton
            id={row._id}
            status={row.status}
            email={row.email}
          ></ActionButton>
        );
      },
    },
  ];

  const auth = useAuthUser();

  useEffect(() => {
    api
      .get("/list-users", {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setLoading(false);
        userListStore.setUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  }, []);

  return !loading ? (
    <Box sx={{ height: 600, width: "100%" }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        rows={toJS(userListStore.users)}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
      />
    </Box>
  ) : (
    <div>Carregando...</div>
  );
});
