import React from "react";
import UserList from "../../components/userList/userList";
import { Container, Grid } from "@mui/material";
import { CustomHeader } from "../../components/header/Header";
import GenerateUserBox from "../../components/generateUserBox/GenerateUserBox";

export default function Dashboard() {
  return (
    <>
      <CustomHeader />

      <Grid container justifyContent="center">
        <GenerateUserBox />
      </Grid>
      <Grid sm={10} md={12} container justifyContent="center">
        <UserList />
      </Grid>
    </>
  );
}
