import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { api } from "../../services/api";
import { CustomHeader } from "../header/Header";
import { IGetList } from "./types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField } from "@mui/material";

export default function ListGenerator() {
  const auth = useAuthUser();
  const [values, setValues] = useState({
    name: "",
    url: "",
  });

  const [list, setList] = useState<IGetList[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);

    api
      .post(
        "/list-generator",
        {
          name: values.name,
          link: values.url,
        },
        {
          headers: { Authorization: auth()?.token },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("Finalizado");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    api.get("/list-generator").then((response) => {
      setList(response.data.data.children);
    });
  }, [isLoading]);

  return (
    <>
      <CustomHeader />

      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px 0",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          id="name"
          label="Nome"
          variant="outlined"
          sx={{ marginRight: "10px" }}
        />
        <TextField
          onChange={(e) => setValues({ ...values, url: e.target.value })}
          id="link"
          label="Link"
          placeholder="www.example.com/user/pass"
          variant="outlined"
          sx={{ marginRight: "10px" }}
        />

        <Button
          onClick={(e) => handleSubmit(e)}
          variant="contained"
          color="success"
        >
          Cadastrar
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.children[1].children}
                </TableCell>
                <TableCell>{row.children[0].props.value}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setIsLoading(true);
                      api
                        .delete(`/list-generator/${row.id}`, {
                          headers: { Authorization: auth()?.token },
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
