import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import userListStore from "../../mobx/userList.store";
import { api } from "../../services/api";

export default function GenerateUserBox() {
  const auth = useAuthUser();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    type: "",
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({ ...newUser, type: (event.target as HTMLInputElement).value });
    setHelperText("");
    setError(false);
  };

  const handleSubmit = () => {
    setLoading(true);

    api
      .post(`/register-${newUser.type}`, newUser, {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setHelperText("Usuário criado com sucesso.");
        setLoading(false);
        api
          .get("/list-users", {
            headers: { Authorization: auth()?.token },
          })
          .then((res) => {
            setLoading(false);
            userListStore.setUsers(res.data.users);
          })
          .catch((err) => {
            console.log(err.response.data);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err.response.data);
        setHelperText("Erro ao criar usuário.");
        setLoading(false);
      });
  };

  const handlePayment = () => {
    setLoading(true);
    api
      .get("/plans/pagamentos", {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };
  const handleMktCron = () => {
    setLoading(true);
    api
      .get("/plans/mkt-cron", {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };
  const handleCron = () => {
    setLoading(true);
    api
      .get("/plans/cron", {
        headers: { Authorization: auth()?.token },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };

  const options = [
    { value: "user", label: "Usuário" },
    { value: "admin", label: "Admin", onlySuperAdmin: true },
    { value: "super-admin", label: "Super Admin", onlySuperAdmin: true },
  ];
  return (
    <>
      <FormControl sx={{ m: 3 }} error={error} variant="standard">
        <Grid>
          <Grid item>
            <FormLabel component="legend">Gerar novo usuário:</FormLabel>
            <TextField
              sx={{ mt: 1, mr: 2 }}
              id="outlined-name"
              label="Nome"
              value={newUser.name}
              onChange={(e) => {
                setNewUser({ ...newUser, name: e.target.value });
              }}
            />
            <TextField
              sx={{ mt: 1, mr: 2 }}
              id="outlined-name"
              label="Email"
              value={newUser.email}
              onChange={(e) => {
                setNewUser({ ...newUser, email: e.target.value });
              }}
            />
            <TextField
              sx={{ mt: 1, mr: 2 }}
              id="outlined-name"
              label="Senha"
              value={newUser.password}
              onChange={(e) => {
                setNewUser({ ...newUser, password: e.target.value });
              }}
            />
          </Grid>
          <Grid item>
            <FormLabel id="demo-error-radios">
              Selecione o tipo de usuário:
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-error-radios"
              name="gerador de usuários"
              value={newUser.type}
              onChange={handleRadioChange}
            >
              {options.map((item) => {
                if (auth()?.role === "superadmin" && item.onlySuperAdmin) {
                  return (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                    />
                  );
                }

                if (!item.onlySuperAdmin) {
                  return (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                    />
                  );
                }
              })}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
            <LoadingButton
              loading={loading}
              sx={{ mt: 1, mr: 1 }}
              type="submit"
              variant="outlined"
              onClick={handleSubmit}
            >
              Gerar novo usuário
            </LoadingButton>
          </Grid>
        </Grid>

        {auth()?.role === "superadmin" && (
          <Grid container mt={4}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                <LoadingButton
                  loading={loading}
                  sx={{ mt: 1, mr: 1 }}
                  type="submit"
                  variant="outlined"
                  onClick={handlePayment}
                >
                  Pagamentos
                </LoadingButton>
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">
                <LoadingButton
                  loading={loading}
                  sx={{ mt: 1, mr: 1 }}
                  type="submit"
                  variant="outlined"
                  onClick={handleMktCron}
                >
                  MKT CRON
                </LoadingButton>
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">
                <LoadingButton
                  loading={loading}
                  sx={{ mt: 1, mr: 1 }}
                  type="submit"
                  variant="outlined"
                  onClick={handleCron}
                >
                  CRON
                </LoadingButton>
              </FormLabel>
            </Grid>
          </Grid>
        )}
      </FormControl>
    </>
  );
}
