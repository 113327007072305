import { makeObservable, observable, action } from "mobx";

class Store {
  ChangePasswordModal = false;

  constructor() {
    makeObservable(this, {
      ChangePasswordModal: observable,
      setModal: action,
    });
  }

  setModal = (modal: boolean) => {
    this.ChangePasswordModal = modal;
  };
}

export default new Store();